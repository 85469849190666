import BasePlugin from '../BasePlugin'
import CalculateFinancialIndicatorsCmp from './CalculateFinancialIndicatorsCmp'

export default class CalculateIndicatorsByAllCalculationTypeIdCommand extends BasePlugin {
  async execute () {
    let recordId = this.context.getModel().id
    this.context.getCard().setLoading(true)
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/FinancialIndicator/CalculateIndicatorsByAllCalculationTypeIdCommand`,
      { 'projectId': recordId, 'groupId': 1 },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      this.context.getCard().setLoading(false)
      let localization = {
        manual_method_not_found: 'метод ручного расчета не найден',
        calculation_error: 'ошибка в расчетах',
        argument_indicator_calculation_error: 'ошибка в расчетах одного и/или нескольких аргументов'
      }
      Object.values(response.data.plan.calculated).forEach(function (period) {
        Object.values(period.error).forEach(function (item) {
          item.error_code = localization[item.error_code]
        })
      })
      Object.values(response.data.fact.calculated).forEach(function (period) {
        Object.values(period.error).forEach(function (item) {
          item.error_code = localization[item.error_code]
        })
      })
      const h = this.context.$createElement
      this.context.$msgbox({
        title: 'Результаты расчетов показателей',
        message: h('p', { key: Math.random() }, [
          h(CalculateFinancialIndicatorsCmp, { props: { model: response.data } })
        ]),
        showCancelButton: false,
        showConfirmButton: false
      })

      this.context.getDashboardComponents()['component_b30668db-f42d-4171-8aa8-ffaea60e9a2c'][0].loadData()
      this.context.getDashboardComponents()['component_fc680399-44b0-43b5-8d4a-3550a0a6dfa4'][0].loadData()
      this.context.getDashboardComponents()['component_d41f5846-5462-47e8-87fa-545bbbbe75c0'][0].loadData()
      setTimeout(function () {
        document.getElementById('container_message_box').parentElement.parentElement.parentElement.parentElement.style.width = '840px'
      }, 500)
    }, (response) => { this.context.getCard().setLoading(false) })
  }
}
